// export const API_URL = 'https://ang16rp827.execute-api.us-east-1.amazonaws.com/feat-gc-1851-2';
export const API_URL = 'https://qa.api.sls.blinkfitness.com';
export const environment = {
  production: false,
  isDebugMode: true,
  isLocalRun: false,
  isOldStackWithCredentials: true,
  GTM_ID: "GTM-NBLGSFX",
  GTM_AUTH: "nv8ywxs1jtEewEt2xCs39w",
  GTM_PREVIEW: "env-5",
  FRIENDBUY_MERCHANT_ID: "0a2f6671-d870-4621-982f-5a9f39013112",
  AWS_CONF: {
    region: 'us-east-1',
    APIregion: 'us-east-1',
    IdentityPoolId: 'us-east-1:f6adb3cb-4514-4159-9ffa-f483db4bbe0e',
    UserPoolId: 'us-east-1_4u204xweC',
    UserPoolIdRegion: 'us-east-1',
    ClientId: '7a52seq6f67m3t09ia0qjnta3s',
    cognito_idp_endpoint: '',
    cognito_identity_endpoint: '',
    storageDomain: '.blinkfitness.com'
  },
  API_CONF: {
    CMS_API: API_URL + '/web.v3.qa',
    MOSO_API: API_URL + '/moso-api-team-noho-qa',
    REFERRALS_API: API_URL + '/referrals-api-team-noho-qa',
    COGNITO_API: API_URL + '/cognito-team-noho-qa',
    PRICING_API: API_URL + '/pricing-api-team-noho-qa',
    PRODUCT_CATALOG_API: API_URL + '/product-catalog-team-noho-qa',
    VALIDATE_PROMOCODE: API_URL + '/promotions-api.qa/promotions',
    WEB_MOSO_API: API_URL + '/web-blink.moso-api-team-noho-qa',
    LEADS_API: API_URL + '/blink-leads-v2-dev',
    YEXT_API: API_URL + '/blink-yext-team-noho-qa',
    blinkContactUsEmail: 'arn:aws:sns:us-east-1:923195502435:web-blink-team-noho-qa-contact-us-email',
    blinkCorporateEmail: 'arn:aws:sns:us-east-1:923195502435:iblink-team-noho-qa-corporate-membership-email',
    X_API_KEY: 'rnig9SAGhp76igYPRtUyK4ycbgkTn6yG8KA9WFPR',
    X_API_KEY_PRODUCT_CATALOG: 'FuBnAnu0NC4SY85myYTzX5vKBj02WBmn7doJGWwe',
    RECAPTCHA_KEY: '6LdsVSsqAAAAACx8NmlUdaGNmdWKFEtNZ-HN-QDK',
    YEXT_KEY: '3ba3101812154852d4c21dacf3942217',
    /** WebSocket URL */
    JOIN_SERVICE: 'wss://kfzmn6bos8.execute-api.us-east-1.amazonaws.com/qa'
  },
  domain: 'https://team-noho-qa-blinkweb.dev.sls.blinkfitness.com',
  domainLocations: 'https://locations.blinkfitness.com',
  domainBlog: 'https://team-noho-qa-blinkblog.blog.blinkfitness.com',
  virtualFitnessBlogRedirectionSlug: 'find-your-perfect-personal-trainer',
  blinkfitnessSocialUrl: 'https://media.cms.blinkfitness.com/media/sites/2/20220222090206/blink-media-background.png',
  iblinkDomain: 'https://team-noho-qa-iblink-frontend.dev.account.blinkfitness.com',
  omnitureSnippetURL: 'https://assets.adobedtm.com/0a037a69b158/b8ed178654fd/launch-dec72d2ad6c0-development.min.js',
  zenDeskWidgetURL: 'https://static.zdassets.com/ekr/snippet.js?key=bbb3827d-3020-4f02-af8e-5c945cf983ed'
};
