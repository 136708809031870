import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { ElementRef, Injectable, QueryList } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

export interface FocusConfig {
  type: string,
  focusableElementId: string,
  isFocusableEnabled?: boolean
}

@Injectable({
  providedIn: 'root',
})
/**
 * This service is for implementing wcag-related functionalities
 */
export class WcagService {
  
  liveAnnounceElementId: string = 'live-announce-elem';
  _wcagFocusConfig: BehaviorSubject<FocusConfig> = new BehaviorSubject(<FocusConfig>{});
  DEFAULT_ANNOUNCEMENTS = {
    SUCCESS: 'Form has been submitted successfully!',
    LOADING: 'Submitting form, please wait...',
    ERROR: 'Something went wrong. Please try again later.'
  }
  DEFAULT_MESSAGE_TYPES = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  }
  /**
   * constructor
   * @param {LiveAnnouncer} _announcer
   */
  constructor(private _announcer: LiveAnnouncer) {}

  /**
   * announces messages assertively for screenreaders
   * @param {string} message
   * @returns void
   */
  announceMessageAssertive(message: string): void {
    this._announcer.announce(message, 'assertive');
  }

  /**
   * announces messages politely for screenreaders
   * @param {string} message
   * @returns void
   */
  announceMessagePolite(message: string): void {
    this._announcer.announce(message, 'polite');
  }

  /**
   * announces status of form submission depending on type passed
   * @param {string} type
   * @param {string} dialogId
   * @param {boolean} addStatusDiv
   * @param {string?} message
   * @returns void
   */
  announceFormStatus(type: string, dialogId: string, addStatusDiv: boolean = true, message?: string): void {
    /* for nvda support */
    this.announceMessageAssertive(message || this.DEFAULT_ANNOUNCEMENTS[type]);
    /* for apple voiceover support */
    if (addStatusDiv) {
      this.addStatusDiv(dialogId, message || this.DEFAULT_ANNOUNCEMENTS[type]);
    }
  }

  /**
   * sets aria owns of live announcer element to fix dialog screenreader issues
   * @param {string} dialogId
   * @returns void
   */
  setAriaOwnOfAnnouncer(dialogId: string): void {
    const liveAnnouncerElement: HTMLElement = document.body.querySelector(
      'div.cdk-live-announcer-element'
    );
    const modal: HTMLElement = document.body.querySelector(`#${dialogId}`);
    if (liveAnnouncerElement && modal) {
      if (!liveAnnouncerElement.id) {
        liveAnnouncerElement.id = this.liveAnnounceElementId;
      }
      const ariaOwnsId = `${modal.getAttribute('aria-owns') || ''} ${
        this.liveAnnounceElementId
      }`;
      modal.setAttribute('aria-owns', ariaOwnsId.trim());
      modal.setAttribute('aria-modal', 'false');
    }
  }

  /**
   * remove aria owns of live announcer element for clean up
   * @param {string} dialogId
   * @returns void
   */
  removeAriaOwnOfAnnouncer(dialogId: string): void {
    const modal: HTMLElement = document.body.querySelector(`#${dialogId}`);
    if (modal) {
      const ariaOwnsId = (modal.getAttribute('aria-owns') || '').replace(
        this.liveAnnounceElementId,
        ''
      );
      modal.setAttribute('aria-owns', ariaOwnsId.trim());
    }
  }

  /**
   * set focus on an element with the specified class
   * @param {string} className
   * @returns void
   */
  initFocusOnPageInit(className?: string): void {
    const classNameToFocus = className || `wcag-first-focus`
    const wcagFirstFocus = document.getElementsByClassName(classNameToFocus);
    if (wcagFirstFocus && wcagFirstFocus[0]) {
      (<HTMLElement>wcagFirstFocus[0]).focus();
    }
  }

  /**
   * set focus on an element with the specified id
   * @param {string} id
   * @returns void
   */
  initFocusOnPageInitWithId(id: string): void {
    const wcagFirstFocus = document.getElementById(id);
    (<HTMLElement>wcagFirstFocus).focus();
  }
  /**
   * set aria-hidden=true for children of heading elements and sets aria-label for the heading elements themselves
   * @param {ElementRef} el
   * @returns void
   */
  ariaHideInnerElementsOfHeadings(el?: ElementRef): void {
    let headings: NodeListOf<HTMLElement>;
    if (el) {
      headings = el.nativeElement.querySelectorAll('h1,h2,h3,h4,h5,h6');
    } else {
      headings = document.querySelectorAll('h1,h2,h3,h4,h5,h6');
    }
    this.groupedAriaLabel(headings);
  }

  /**
   * set aria-hidden=true for children of button elements and sets aria-label for the button elements themselves
   * @param {ElementRef} el
   * @returns void
   */
  ariaHideInnerElementsOfButtons(el?: ElementRef): void {
    let buttons: NodeListOf<HTMLElement>;
    if (el) {
      buttons = el.nativeElement.querySelectorAll('button');
    } else {
      buttons = document.querySelectorAll('button');
    }
    this.groupedAriaLabel(buttons);
  }

  /**
   * given a list of elementRef, set a proper aria-label and prevent screen-reader from reading the inner contents separately
   * @param {QueryList<ElementRef | HTMLElement>} elements
   * @param {boolean} ariaHideChildren
   * @returns void
   */
  groupedAriaLabel(
    elements: QueryList<ElementRef> | NodeListOf<HTMLElement>,
    ariaHideChildren: boolean = true,
    ariaRole?: string
  ): void {
    elements.forEach((element: ElementRef | HTMLElement) => {
      const nativeElement: HTMLElement =
        element instanceof ElementRef
          ? (element?.nativeElement as HTMLElement)
          : element;
      if (!nativeElement.children) {
        return;
      }
      // stripped html from inside text and set it as aria-label
      const strippedText = (
        nativeElement?.innerText || nativeElement?.textContent
      )
        ?.trim()
        .replace(/(\r\n|\r|\n)/g, ' ')
        .replace(/\s+/g, ' ');
      if (!nativeElement.getAttribute('aria-label') && strippedText) {
        nativeElement.setAttribute('aria-label', strippedText);
      }
      if (ariaRole) {
        nativeElement.setAttribute('role', ariaRole);
      }

      // aria-hidden true it's children
      const children = Array.from(nativeElement.children);
      children.forEach((child: HTMLElement) => {
        if (ariaHideChildren) {
          if (!child.getAttribute('aria-hidden')) {
            child.setAttribute('aria-hidden', 'true');
          }
        }
        if (
          !nativeElement.getAttribute('aria-label') &&
          (child.getAttribute('aria-label') || child.getAttribute('alt'))
        ) {
          nativeElement.setAttribute(
            'aria-label',
            child.getAttribute('aria-label') || child.getAttribute('alt')
          );
        }
      });
    });
  }

  /**
   * sets the aria-label for anchor tags with target=_blank and adds a suffix that will warn that it will open in a new tab
   * @param {ElementRef} el
   * @returns void
   */
  ariaAddNewTabWarningForAnchors(el?: ElementRef): void {
    let anchorElements: NodeListOf<HTMLAnchorElement>;

    if (el) {
      anchorElements = el.nativeElement.querySelectorAll('a');
    } else {
      anchorElements = document.querySelectorAll('a');
    }

    anchorElements.forEach((anchorElement) => {
      const strippedText =
        anchorElement.innerText || `Redirects you to ${anchorElement.href}`;
      let activeLink = true;

      const style = getComputedStyle(anchorElement);
      if (style.pointerEvents == 'none') {
        activeLink = false;
      }

      if (anchorElement.getAttribute('target') == '_blank' && activeLink) {
        anchorElement.classList.remove('deactivate-link');
        anchorElement.setAttribute(
          'aria-label',
          `${strippedText}. (Opens in new tab).`
        );
      }
    });
  }

  /**
   * set aria-hidden=true on elements of a given selector because it is not necessary for screenreaders to see them
   * @param {string} querySelector
   * @returns void
   */
  ariaHideElements(querySelector: string): void {
    const elementsToHide: NodeListOf<HTMLElement> =
      document.querySelectorAll(querySelector);
    Array.from(elementsToHide).forEach((el: HTMLElement) => {
      el.setAttribute('aria-hidden', 'true');
    });
  }
  /**
   * Return observable for wcagFocusConfig
   * @returns Observable<FocusConfig>
   */
  getWcagFocusConfig(): Observable<FocusConfig> {
    return this._wcagFocusConfig.asObservable();
  }
  /**
   * Emits an event to wcagFocusConfig
   * @param {FocusConfig} message
   */
  setWcagFocusConfig(message: FocusConfig): void {
    this._wcagFocusConfig.next(message)
  }

  /**
   * set aria-label=true to inner input of mat-checkbox
   * @param {string} inputId
   * @param {string} ariaLabel
   * @param {NodeJS.Timeout} timeout
   * @param {number} delay
   * @returns void
   */
  addAriaLabelToCheckboxInput(inputId: string, ariaLabel: string, timeout: NodeJS.Timeout, delay: number) {
    /* for nvda screenreader support */
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      const checkBoxInput = document.body.querySelector(`#${inputId}-input`);
      checkBoxInput?.setAttribute('aria-label', ariaLabel);
    }, delay);
  }

  /**
   * set adds a div to dialog with role="status"
   * @param {string} parentId
   * @param {string} message
   * @returns void
   */
  addStatusDiv(parentId: string, message: string) {
    this.removeStatusDiv(parentId);
    const statusDiv = document.createElement('div');
    statusDiv.classList.add('screenreader-only');
    statusDiv.setAttribute('role', 'status');
    statusDiv.setAttribute('tabindex', '-1');
    statusDiv.id = `${parentId}-accessibility-status`;
    statusDiv.innerText = message;
    document.getElementById(parentId)?.append(statusDiv);
  }

  /**
   * removes div to dialog with role="status"
   * @param {string} querySelector
   * @returns void
   */
  removeStatusDiv(parentId: string) {
    const statusDivId = `${parentId}-accessibility-status`;
    document.getElementById(statusDivId)?.remove();
  }
}
