import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { YouTubePlayer } from '@angular/youtube-player';
import {Analytics, YoutubeEvents} from './youtube-player.types';
import { GTMEvent, TagManagerClickDirectiveHelper } from '@shared/directives/tag-manager.directive';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

/**
 * Youtube Player component
 */
@Component({
  selector: 'bw-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class YoutubePlayerComponent implements OnInit{
  @ViewChild('player') _player: YouTubePlayer;
  @Input() videoId: string;
  @Input() analytics: Analytics;
  @Input() width: number | string;
  @Input() height: number | string;
  @Input() suggestedQuality: string;
  @Output() onReady = new EventEmitter();
  @Output() onStateChange = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onApiChange = new EventEmitter();
  @Output() onPlaybackQualityChange = new EventEmitter();
  @Output() onPlaybackRateChange = new EventEmitter();

  constructor(private gtmService: GoogleTagManagerService) {}

  ngOnInit(): void {
    this.appendYTIFrameAPIScript();
  }

  /**
   * Append YouTube IFrame API for the youtube-player
   */
  appendYTIFrameAPIScript(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  /**
   * do(which: YoutubeEvents, ...rest): any
   * Call youtube player methods; which is the name of the method e.g.: playVideo
   * reference: https://developers.google.com/youtube/iframe_api_reference#Functions
   *
   * @param which string
   */
  do(which: YoutubeEvents, ...rest: any[]): any {
    const method = get(this, `_player.${which}`);

    if (method && (typeof method === 'function')) {
      return method.apply(null, ...rest);
    }

    return null;
  }

  _onStateChange(event: any) {
    if (this.analytics){
      switch (event.data){
        case YT.PlayerState.PAUSED:
          this.sendEventToGTM(this.analytics.name, this.analytics.pause);
          break;
        case YT.PlayerState.PLAYING:
          this.sendEventToGTM(this.analytics.name, this.analytics.play);
          break;
        case YT.PlayerState.ENDED:
          this.sendEventToGTM(this.analytics.name, this.analytics.end);
          break;
      }
    }
    this.onStateChange.emit(event);
  }

  sendEventToGTM(video: string, clickType: string): void{
    const pageData = TagManagerClickDirectiveHelper.getBodyPageData();
    if (pageData != null) {
      this.gtmService.pushTag({
        ...TagManagerClickDirectiveHelper.getDefaultEventData(GTMEvent.Click, pageData),
        "clickType": clickType,
        "video": video,
      });
    }
  }

}
