export const regionsWithCities = [
  {
    abbreviation: 'IL',
    cities: [
      { name: 'Oak Lawn' },
      { name: 'Chicago' },
      { name: 'Merrionette Park' },
      { name: 'Evanston' },
      { name: 'Riverside' }
    ]
  },
  {
    abbreviation: 'VA',
    cities: [
      { name: 'Virginia Beach' }
    ]
  },
  {
    abbreviation: 'NY',
    cities: [
      { name: 'Yonkers' },
      { name: 'New York' },
      { name: 'Bronx' },
      { name: 'Brooklyn' },
      { name: 'Melville' },
      { name: 'Queens' },
      { name: 'West Islip' },
      { name: 'Selden' },
      { name: 'Brentwood' },
      { name: 'White Plains' },
      { name: 'Islandia' },
      { name: 'Farmingdale' },
      { name: 'Liverpool' },
      { name: 'Lindenhurst' },
      { name: 'Syracuse' },
      { name: 'Rochester' },
      { name: 'The Bronx' },
      { name: 'Ridgewood' },
      { name: 'Astoria' },
      { name: 'Jackson Heights' },
      { name: 'Baldwin' },
      { name: 'Valley Stream' },
      { name: 'Hicksville' }
    ]
  },
  {
    abbreviation: 'MA',
    cities: [
      { name: 'Beverly' },
      { name: 'Medford' }
    ]
  },
  {
    abbreviation: 'NJ',
    cities: [
      { name: 'Paramus' },
      { name: 'Plainfield' },
      { name: 'Passaic' },
      { name: 'Willingboro' },
      { name: 'Perth Amboy' },
      { name: 'East Orange' },
      { name: 'Newark' },
      { name: 'Nutley' },
      { name: 'Jersey City' },
      { name: 'Parsippany' },
      { name: 'Lodi' },
      { name: 'Linden' },
      { name: 'South Orange' },
      { name: 'Irvington' },
      { name: 'Union' },
      { name: 'Clifton' }
    ]
  },
  {
    abbreviation: 'CA',
    cities: [
      { name: 'Huntington Park' },
      { name: 'Ontario' },
      { name: 'Los Angeles' },
      { name: 'Rialto' },
      { name: 'Bellflower' },
      { name: 'Gardena' },
      { name: 'Anaheim' },
      { name: 'Burbank' }
    ]
  },
  {
    abbreviation: 'MI',
    cities: [
      { name: 'Warren' },
      { name: 'Redford' }
    ]
  },
  {
    abbreviation: 'FL',
    cities: [
      { name: 'Jacksonville' },
      { name: 'Miramar' }
    ]
  },
  {
    abbreviation: 'TX',
    cities: [
      { name: 'Keller' },
      { name: 'North Richland Hills' },
      { name: 'Bedford' },
      { name: 'Fort Worth' },
      { name: 'Houston' }
    ]
  }
];
