import { Component, DestroyRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { PopupService } from '@core/services/popup.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { constants } from '@core/const/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const PATTERNS = constants.PATTERNS;
@Component({
    selector: 'bw-dialog-trigger-dismissal',
    styleUrls: ['./dialog-trigger-dismissal.component.scss'],
    templateUrl: './dialog-trigger-dismissal.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DialogTriggerDismissalComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public active: boolean;
    public formVisible: boolean;
    public closeTriggerDismissal: boolean;
    public form: UntypedFormGroup;
    public options: any;
    public desktopOnly: boolean;

    constructor(private popupService: PopupService, private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.createForm();
        this.popupService._dialogTriggerDismissal
        .pipe(takeUntilDestroyed(this.destroyRef)).subscribe((options: any) => {
            if (!options) {
                this.active = false;
            } else {
                this.options = options;
                this.active = options.active;
                this.formVisible = options.formVisible;
            }
        });
        this.desktopOnly = window.innerWidth < 1025 ? false : true;
        // this.desktopOnly = window.innerWidth < 1025;
    }

    createForm(): void {
        this.form = this.fb.group({
            firstName: [null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100)
            ]],
            lastName: [null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100)
            ]],
            email: [null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100),
                Validators.pattern(PATTERNS.EMAIL)
            ]],
            confirmEmail: [null, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100),
                Validators.pattern(PATTERNS.EMAIL),
                EmailValidator('email'),
            ]]
        });
    }

    closeDialogTriggerDismissal() {
        this.closeTriggerDismissal = true;
        setTimeout(() => {
            this.popupService.closeDialogTriggerDismissal();
        }, 3000);
    }

    onSubmit(data: any) {
        this.popupService.showDialogTriggerDismissalThankYou();
        setTimeout(() => {
            this.closeDialogTriggerDismissal();
        }, 5000);
    }
}


export function EmailValidator(confirmEmailInput: string) {
    let confirmEmailControl: UntypedFormControl;
    let emailControl: UntypedFormControl;

    return (control: UntypedFormControl) => {
        if (!control.parent) {
            return null;
        }

        if (!confirmEmailControl) {
            confirmEmailControl = control;
            emailControl = control.parent.get(confirmEmailInput) as UntypedFormControl;
            emailControl.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                confirmEmailControl.updateValueAndValidity();
            });
        }

        if (emailControl.value !== confirmEmailControl.value) {
            return {
                notMatch: true
            };
        }
        return null;
    };
}

