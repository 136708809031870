/* eslint-disable no-useless-escape */

import { environment } from '@environment';
import { IUSAState } from '@models';
import { regionsWithCities } from './regions-with-cities';

const allStatesList: IUSAState[] = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
    hasClub: true,
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    hasClub: false,
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    hasClub: true,
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    hasClub: true,
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    hasClub: true,
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    hasClub: true,
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    hasClub: true,
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    hasClub: false,
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const constants = {
  topHeaderNavigationItems: [
    {
      title: 'Account',
      link: '/iblink/info',
      icon: 'bi bi-gear-fill'
    },
    {
      title: 'Billing',
      link: '/iblink/billing',
      icon: 'bi bi-credit-card-fill'
    },
    {
      title: 'BFF Rewards',
      link: '/iblink/referrals',
      icon: 'bi bi-people-fill'
    },
    {
      title: 'Personal Training',
      link: '/iblink/startup',
      icon: 'bi bi-star-fill'
    },
    {
      title: 'FAQ',
      link: environment.domain + '/faq',
      icon: 'bi bi-patch-question-fill'
    },
    {
      title: 'LogOut',
      link: environment.domain + '/v1/users/logout',
      icon: 'bi bi-box-arrow-right'
    }],
  menusHidden: [
    {
      title: 'Locations',
      link: environment.domain + '/Locations'
    },
    {
      title: 'Why Blink',
      link: environment.domain + '/PersonalTraining'
    },
    {
      title: 'Personal Training',
      link: environment.domain + '/PersonalTraining'
    },
    {
      title: 'Own a Blink',
      link: '//www.blinkfranchising.com'
    },
    {
      title: 'Free Trial',
      link: '#'
    },
    {
      title: 'Join now',
      link: '#',
      highlight: true
    }],
  footerMenus: [
    {
      title: 'Corporate Program',
      link: document.location.pathname + '?modal=corporate',
      type: 'internal',
    },

    {
      title: 'Franchising',
      link: '//www.blinkfranchising.com/?othcid=BFit-Redir',
      type: 'external',
    },

    {
      title: 'Privacy Policy',
      link: '/privacy',
      type: 'internal',
    },

    {
      title: 'Terms Of Use',
      link: '/terms',
      type: 'internal',
    }],
    footerSocialMenus: [
      {
        logoSrc: '../../../../assets/img/BlueLogo.svg',
        width: 100,
        href: '/',
        accessibilityText: 'Go to Blink Homepage'
      },
  
      {
        iconClasses: 'bi bi-facebook text-dark-grey icon',
        href: 'https://www.facebook.com/BlinkFitness',
        target: '_blank',
        accessibilityText: 'Go to Blink Facebook'
      },
  
      {
        iconClasses: 'bi bi-twitter-x text-dark-grey icon',
        href: 'https://twitter.com/blinkfitness',
        target: '_blank',
        accessibilityText: 'Go to Blink Twitter'
      },
      {
        iconClasses: 'bi bi-instagram text-dark-grey icon',
        href: 'https://www.instagram.com/blinkfitness/',
        target: '_blank',
        accessibilityText: 'Go to Blink Instagram'
      }],
  appointmentTimeList: [
    {
      name: 'Morning',
      value: 'Morning'
    },
    {
      name: 'Afternoon',
      value: 'Afternoon'
    },
    {
      name: 'Evening',
      value: 'Evening'
    },
    {
      name: 'Anytime',
      value: 'Anytime'
    }
  ],
  fitnessGoalList: [
    {
      name: 'Lose Weight',
      value: 'Lose Weight'
    },
    {
      name: 'Improve Strength',
      value: 'Improve Strength'
    },
    {
      name: 'Boost Confidence',
      value: 'Boost Confidence'
    },
    {
      name: 'Stay Active',
      value: 'Stay Active'
    },
    {
      name: 'Upcoming Event',
      value: 'Upcoming Event'
    },
    {
      name: 'Other',
      value: 'Other'
    }
  ],
  gymLocationList: [
    {
      name: 'Blink 116th',
      value: '619',
      gymEmail: '116street@blinkfitness.com'
    },
    {
      name: 'Blink 125th Street',
      value: '610',
      gymEmail: '125street@blinkfitness.com'
    },
    {
      name: 'Blink 54th',
      value: '645',
      gymEmail: '54thstreet@blinkfitness.com'
    },
    {
      name: 'Blink Astoria',
      value: '624',
      gymEmail: 'astoria@blinkfitness.com'
    },
    {
      name: 'Blink Baldwin',
      value: '631',
      gymEmail: 'baldwin@blinkfitness.com'
    },
    {
      name: 'Blink Boerum Hill',
      value: '638',
      gymEmail: 'boerumhill@blinkfitness.com'
    },
    {
      name: 'Blink Brentwood',
      value: '616',
      gymEmail: 'brentwood@blinkfitness.com'
    },
    {
      name: 'Blink Bryant Park',
      value: '634',
      gymEmail: 'bryantpark@blinkfitness.com'
    },
    {
      name: 'Blink Bushwick',
      value: '650',
      gymEmail: 'bushwick@blinkfitness.com'
    },
    {
      name: 'Blink Canarsie',
      value: '643',
      gymEmail: 'canarsie@blinkfitness.com'
    },
    {
      name: 'Blink Clifton',
      value: '639',
      gymEmail: 'clifton@blinkfitness.com'
    },
    {
      name: 'Blink Concourse Village',
      value: '618',
      gymEmail: 'concoursevillagewest@blinkfitness.com'
    },
    {
      name: 'Blink Coney Island',
      value: '647',
      gymEmail: 'coneyisland@blinkfitness.com'
    },
    {
      name: 'Blink Flatbush',
      value: '613',
      gymEmail: 'flatbush@blinkfitness.com'
    },
    {
      name: 'Blink Gates',
      value: '628',
      gymEmail: 'gates@blinkfitness.com'
    },
    {
      name: 'Blink Grand Central',
      value: '649',
      gymEmail: 'grandcentral@blinkfitness.com'
    },
    {
      name: 'Blink Grand Concourse',
      value: '621',
      gymEmail: 'grandconcourse@blinkfitness.com'
    },
    {
      name: 'Blink Hicksville',
      value: '648',
      gymEmail: 'hicksville@blinkfitness.com'
    },
    {
      name: 'Blink Islandia',
      value: '612',
      gymEmail: 'islandia@blinkfitness.com'
    },
    {
      name: 'Blink Journal Square',
      value: '625',
      gymEmail: 'journalsquare@blinkfitness.com'
    },
    {
      name: 'Blink Junction',
      value: '606',
      gymEmail: 'nostrand@blinkfitness.com'
    },
    {
      name: 'Blink Linden',
      value: '640',
      gymEmail: 'linden@blinkfitness.com'
    },
    {
      name: 'Blink Lindenhurst',
      value: 'FNY302',
      gymEmail: 'Lindenhurst@blinkfitness.com'
    },
    {
      name: 'Blink Macombs',
      value: '636',
      gymEmail: 'macombs@blinkfitness.com'
    },
    {
      name: 'Blink Melville',
      value: '607',
      gymEmail: 'melville@blinkfitness.com'
    },
    {
      name: 'link Murray Hill',
      value: '611',
      gymEmail: 'murrayhill@blinkfitness.com'
    },
    {
      name: 'Blink Nassau St',
      value: '642',
      gymEmail: 'nassau@blinkfitness.com'
    },
    {
      name: 'Blink NoHo',
      value: '601',
      gymEmail: 'noho@blinkfitness.com'
    },
    {
      name: 'Blink Ozone Park',
      value: '646',
      gymEmail: 'ozonepark@blinkfitness.com'
    },
    {
      name: 'Blink Paramus',
      value: '603',
      gymEmail: 'paramus@blinkfitness.com'
    },
    {
      name: 'Blink Parkchester',
      value: '605',
      gymEmail: 'parkchester@blinkfitness.com'
    },
    {
      name: 'Blink Parsippany',
      value: '626',
      gymEmail: 'parsippany@blinkfitness.com'
    },
    {
      name: 'Blink Riverdale',
      value: '644',
      gymEmail: 'riverdale@blinkfitness.com'
    },
    {
      name: 'Blink Roosevelt',
      value: '629',
      gymEmail: 'roosevelt@blinkfitness.com'
    },
    {
      name: 'Blink Selden',
      value: '615',
      gymEmail: 'selden@blinkfitness.com'
    },
    {
      name: 'Blink Southern Boulevard',
      value: '657',
      gymEmail: 'southernboulevard@blinkfitness.com'
    },
    {
      name: 'Blink St Anns',
      value: '608',
      gymEmail: 'stanns@blinkfitness.com'
    },
    {
      name: 'Blink Union',
      value: '637',
      gymEmail: 'union@blinkfitness.com'
    },
    {
      name: 'Blink Valley Stream',
      value: '641',
      gymEmail: 'valleystream@blinkfitness.com'
    },
    {
      name: 'Blink West Islip',
      value: '614',
      gymEmail: 'westislip@blinkfitness.com'
    },
    {
      name: 'Blink Williamsburg',
      value: '658',
      gymEmail: 'williamsburg@blinkfitness.com'
    },
    {
      name: 'Blink Willingboro',
      value: '663',
      gymEmail: 'willingboro@blinkfitness.com'
    },
    {
      name: 'Blink Wissinoming',
      value: '666',
      gymEmail: 'wissinoming@blinkfitness.com'
    },
    {
      name: 'Blink White Plains',
      value: '617',
      gymEmail: 'whiteplains@blinkfitness.com'
    },
    {
      name: 'Blink Wooside',
      value: '652',
      gymEmail: 'woodside@blinkfitness.com'
    },
    {
      name: 'Blink Yonkers',
      value: '602',
      gymEmail: 'yonkers@blinkfitness.com'
    }
  ],
  allStatesList,
  statesList: allStatesList
    .filter((s) => s.hasClub)
    .map(v => ({
      ...v,
      href: `${environment.domainLocations}/${v.abbreviation.toLowerCase()}`,
      cities: regionsWithCities.find(rwc => rwc.abbreviation === v.abbreviation)?.cities,
    })),
  monthsList: [
    {
      name: 'January',
      short: 'Jan',
      number: 1,
      days: 31
    },
    {
      name: 'February',
      short: 'Feb',
      number: 2,
      days: 28
    },
    {
      name: 'March',
      short: 'Mar',
      number: 3,
      days: 31
    },
    {
      name: 'April',
      short: 'Apr',
      number: 4,
      days: 30
    },
    {
      name: 'May',
      short: 'May',
      number: 5,
      days: 31
    },
    {
      name: 'June',
      short: 'Jun',
      number: 6,
      days: 30
    },
    {
      name: 'July',
      short: 'Jul',
      number: 7,
      days: 31
    },
    {
      name: 'August',
      short: 'Aug',
      number: 8,
      days: 31
    },
    {
      name: 'September',
      short: 'Sep',
      number: 9,
      days: 30
    },
    {
      name: 'October',
      short: 'Oct',
      number: 10,
      days: 31
    },
    {
      name: 'November',
      short: 'Nov',
      number: 11,
      days: 30
    },
    {
      name: 'December',
      short: 'Dec',
      number: 12,
      days: 31
    }
  ],
  PATTERNS: {
    EMAIL: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
    ANTIBOT: /^(?!.*(@qq\.com))/gmi,
    ZIP: /(^[0-9]{5}$)|(^[0-9]{9}$)|(^[0-9]{5}-[0-9]{4}$)/g,
    PHONE: '\\d{3}([ .-])?\\d{3}([ .-])?\\d{4}|\\(\\d{3}\\)([ ])?\\d{3}([.-])?\\d{4}|\\(\\d{3}\\)([ ])?\\d{3}([ ])?\\d{4}|\\(\\d{3}\\)([.-])?\\d{3}([.-])?\\d{4}|\\d{3}([ ])?\\d{3}([ .-])?\\d{4}',
    PHONE_V2: /\(\d\d\d\)\ \d\d\d\-\d\d\d\d/,
    BIRTHDAY: '^(0?[1-9]|1[0-2])[\/](0?[1-9]|[12]\d|3[01])[\/](19|20)\d{2}$',
    CORPORATE_MEMBERSHIP_TEXT: /^\S[A-Za-z \."'-]*$/,
    CORPORATE_MEMBERSHIP_TEXT2: /^\S[A-Za-z _."'-]*$/,
    CITY: /^\S[A-Za-z \.-]*$/,
    NUMBER: /^\d+$/,
    DIGITS: /\d/g,
    ADDRESS: /^[A-Za-z0-9 #\$\-\._\(\),&\/]*$/,
    ACCOUNT_HOLDER_NAME: /^(?:[A-Za-z]+ ?){1,3}$/,
    MOSO_COGNITO_ALLOWED_NAME_PATTERN: /^[0-9A-Za-z _.,\-'()]+$/,
    MOSO_COGNITO_ALLOWED_NAME_CHARACTERS: "-'.,()_"
  },
  WCAG_FOCUS_TYPES: {
    PURCHASE_DETAILS: 'PURCHASE_DETAILS',
    PAYMENT_DETAILS: 'PAYMENT_DETAILS'
  },
  CorporateAreas: [
    { value: "Manhattan" },
    { value: "Brooklyn" },
    { value: "Bronx" },
    { value: "Queens" },
    { value: "New Jersey" },
    { value: "Long Island" },
    { value: "Westchester" },
    { value: "Pennsylvania" },
    { value: "Other" },
  ],
  CorporateEmployees: [
    { value: "10-49" },
    { value: "50-99" },
    { value: "100-499" },
    { value: "500-999" },
    { value: "1000+" },
  ]
};

export const COLOR_ACCESSIBILITY_ENABLED_COOKIE_NAME = 'colorAccessibilityEnabled';