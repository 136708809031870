import { Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PopupService } from '@core/services/popup.service';
import { constants } from '@core/const/constants';
import { validateAllFormFields } from '@core/helpers';
import { FacadeService } from '@core/services/core/facade.service';
import { environment } from '@environment';
import { SpinnerService } from '@shared/components/spinner/spinner.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import PhoneValidator from '@shared/validators/phone.validator';
import { IOption } from '@shared/components/select/select.types';
import { ToastService } from '@core/services/helpers/toast.service';
import { Router } from '@angular/router';
import { ICorporateFormData } from './corporate-modal.component.types';
import { messagesOptions } from '@core/const/massage-options';

const PATTERNS = constants.PATTERNS;
declare const $: any;

@Component({
    selector: 'bw-corporate-modal',
    templateUrl: './corporate-modal.component.html',
    styleUrls: ['./corporate-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CorporateModalComponent implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    corporateModal: UntypedFormGroup;
    template: string;
    locations: any;
    opt_employees: IOption[];
    opt_areas: IOption[];

    public get submitDisabled(): boolean {
        return this.corporateModal.invalid || this.corporateModal.pending;
      }

    constructor(
        private _facadeService: FacadeService,
        private _popupService: PopupService,
        private _spinnerService: SpinnerService,
        private _toastService: ToastService,
        public router: Router) {
    }

    ngOnInit() {
        this.template = '<img class="sp" src="./assets/img/loader.svg" alt="Loading..." />';
        this._popupService._corporateModal
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((res: boolean) => {
                if (res) {
                    $('#corporateModal').modal('show');
                } else {
                    $('#corporateModal').modal('hide');
                    this.router.navigate(['.']);
                }
            });

        $('#corporateModal').on('hidden.bs.modal', (e) => {
            this._popupService.turnOffCorporateModal();
        });
        this.createForm();
    }

    formValidation(controlName: string): boolean {
        return this.corporateModal.get(controlName).invalid && (this.corporateModal.get(controlName).touched || this.corporateModal.get(controlName).dirty)
    }

    errorMessage(controlName: string): string {
        if (!this.formValidation(controlName)) return "";
        if (this.corporateModal.get(controlName).hasError('required'))
            return "Required field";
        else if (this.corporateModal.get(controlName).hasError('minlength')) {
            return `Min ${this.corporateModal.get(controlName).errors.minlength.requiredLength} characters`;
        }
        else if (this.corporateModal.get(controlName).hasError('maxlength')) {
            return `Min ${this.corporateModal.get(controlName).errors.maxlength.requiredLength} characters`;
        }
        else if (this.corporateModal.get(controlName).hasError('pattern')) {
            return `Invalid format.`;
        }
    }

    createForm(): void {
        this.opt_employees = constants.CorporateEmployees;
        this.opt_areas = constants.CorporateAreas;
        this.corporateModal = new UntypedFormGroup({
            company_name: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)
            ]),
            number_of_employees: new UntypedFormControl('', []),
            first_name: new UntypedFormControl('', [
                Validators.required, Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)
            ]),
            last_name: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
                Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)]),
            email: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100),
                Validators.pattern(PATTERNS.EMAIL),
                Validators.pattern(PATTERNS.ANTIBOT),
                Validators.pattern(PATTERNS.EMAIL)]),
            phone: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(15),
                Validators.pattern(PATTERNS.PHONE),
                PhoneValidator]
            ),
            area_of_interest: new UntypedFormControl('', [
                Validators.required
            ]),
        });
    }

    /**
     * Sends the form data to the email
     * @param {ICorporateFormData} data 
     */
    async _sendEmail(data: ICorporateFormData): Promise<unknown> {
        return this._facadeService.setUnauthIdentityCredentials()
            .then(() => this._facadeService.getIdenityCredentials())
            .then((credentials) => {
                if (credentials.identityId) {
                    const message = {
                        'email': data.email as string,
                        'first_name': data.first_name as string,
                        'lastname': data.last_name as string,
                        'phone': data.phone as string,
                        'company_name': data.company_name as string,
                        'numbers_employeers': data.number_of_employees as string,
                        'area_interest': data.area_of_interest as string
                    };
                    return this._facadeService.sendEmailWithSnS(message, environment.API_CONF.blinkCorporateEmail);
                }
            });
    }

    /**
     * Gets the form data and validate it
     * @param {ICorporateFormData} value 
     */
    onSubmit(value: ICorporateFormData): void {
        if (this.corporateModal.valid) {
            this._spinnerService.show();
            this._sendEmail(value)
                .then(() => {
                    this._toastService.setToastVisible(messagesOptions.corporateModalSuccess);     
                    this.fomrSubmittedActions();
                })
                .catch(() => {
                    this._toastService.setToastVisible(messagesOptions.corporateModalError);  
                    this.fomrSubmittedActions();
                });
        } else {
            validateAllFormFields(this.corporateModal);
        }
    }

    fomrSubmittedActions(): void {
        this.corporateModal.reset();
        this._spinnerService.hide();
    }

    ngOnDestroy(): void {
        this.corporateModal.reset();
    }
}


