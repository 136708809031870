import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from './components/spinner/spinner.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/** Components Import **/
import { ContainerComponent } from './components/container/container.component';
/** Directive / Validators Import **/
import { CompareDirective } from './directives/compare.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { LazyLoadImageDirective } from './directives/lazy-load-image.directive';
import { SetLimitDirective } from './validators/set-limit.directive';
import { OnlyNumberDirective } from './directives/onlyNumber.directive';
import { WcagDirective } from './directives/wcag.directive';
import { TagManagerLoadDirective, TagManagerClickDirective, TagManagerSubClickDirective, TagManagerFooterClickDirective, TagManagerHeaderClickDirective } from './directives/tag-manager.directive';
import { PhoneNumberValidatorDirective } from './directives/phone-number-validator.directive';
import { BriteVerifyValidator } from './validators';
/** Pipes Import **/
import { ArraySortPipe } from './pipes/sort-pipe';
import { SafeResourcePipe } from './pipes/safe-resource-pipe';
import { SafeHtmlPipe } from './pipes/safe-html-pipe';
import { DisplayOnDevice } from './pipes/display-on-device.pipe';
import { DefineImageAlt } from './pipes/define-image-alt.pipe';
/** Modals Import **/
import { ModalComponent } from './modals/modal/modal.component';
import { DialogTriggerDismissalComponent } from './modals/dialog-trigger-dismissal/dialog-trigger-dismissal.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { WarningModalComponent } from './modals/warning-modal/warning-modal.component';
import { EmergencyModalComponent } from './modals/emergency-modal/emergency-modal.component';
import { CorporateModalComponent } from './modals/corporate-modal/corporate-modal.component';
import { SweepstakesPopupComponent } from './modals/sweepstakes-popup/sweepstakes-popup.component';
import { LocationLeadPopupComponent } from './modals/locationLead-popup/locationLead-popup.component';

import { TermsCheckboxComponent } from './components/terms-checkbox/terms-checkbox.component';
import { TermsContentComponent } from './components/terms-content/terms-content.component';
import { StickerComponent } from './components/sticker/sticker.component';
import { ResponsiveImageComponent } from './components/responsive-image/responsive-image.component';
import { ListImagesComponent } from './components/list-images/list-images.component';
import { YoutubePlayerComponent } from './components/youtube-player/youtube-player.component';
import { YoutubePlayerModalComponent } from './components/youtube-player-modal/youtube-player-modal.component';

import { TermsPageComponent } from './components/terms-page/terms-page.component';
import { TrialComponent } from './modals/free-trial/trial.component';
import { FormModalComponent } from './modals/form-modal/form-modal.component';
import { FormBaseComponent } from './components/form-base/form-base.component';
import { LayoutHostListenerDirective } from './directives/layout-host-listener.directive';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { InputButtonGroupComponent } from './components/input-button-group/input-button-group.component';
import { SelectModule } from './components/select/select.module';
import { SelectLocationsComponent } from './components/select-locations/select-locations.component';
import { MaterialModule } from '@material/material.module';
import { FindLocationsComponent } from './components/find-locations/find-locations.component';
import { PillsComponent } from './components/pills/pills.component';
import { WorkingHoursComponent } from './components/working-hours/working-hours.component';
import { FormStyledComponent } from './components/form-styled/form-styled.component';
import { FormComponent } from './components/form/form.component';
import { BlinkWCAGHelperComponentsAngularModule, ScreenreaderErrorsComponent } from '@blinkfitness/blink-wcag-helper-components'

/* Rebrand Components */
import { TwoBlocksComponent } from './components/two-blocks-layout/two-blocks-layout.component';
import { HeroImageComponent } from './components/hero-image-layout/hero-image-layout.component';
import { AlternatedRowsLayoutComponent } from './components/alternated-rows-layout/alternated-rows-layout.component';
import { ImageTextColumnComponent } from './components/image-text-column/image-text-column.component';
import { PromiseFeaturesComponent } from './components/promise-features/promise-features.component';
import { EmergencyBannerComponent } from './components/emergency-banner/emergency-banner.component';
import { AccordionImageLayoutComponent } from './components/accordion-image-layout/accordion-image-layout.component';
import { SimpleCheckboxComponent } from './components/simple-checkbox/simple-checkbox.component';
import { ImageByDeviceComponent } from './components/image-by-device/image-by-device.component';
import { CountdownModalComponent } from './modals/countdown-modal/countdown-modal.component';
import { RegExpDirective } from './directives/regexp.directive';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule.forRoot(),
    RecaptchaModule,
    MaterialModule,
    YouTubePlayerModule,
    RouterModule,
    SelectModule,
    BlinkWCAGHelperComponentsAngularModule
  ],
  providers: [
    BriteVerifyValidator,
  ],
  declarations: [
    ModalComponent,
    DialogTriggerDismissalComponent,
    ErrorModalComponent,
    FormBaseComponent,
    TrialComponent,
    FormModalComponent,
    WarningModalComponent,
    EmergencyModalComponent,
    CorporateModalComponent,
    SweepstakesPopupComponent,
    LocationLeadPopupComponent,
    CompareDirective,
    NumberOnlyDirective,
    LazyLoadImageDirective,
    SetLimitDirective,
    PhoneNumberValidatorDirective,
    OnlyNumberDirective,
    WcagDirective,
    TagManagerLoadDirective,
    TagManagerClickDirective,
    TagManagerSubClickDirective,
    TagManagerFooterClickDirective,
    TagManagerHeaderClickDirective,
    ArraySortPipe,
    TermsCheckboxComponent,
    TermsContentComponent,
    ContainerComponent,
    StickerComponent,
    ResponsiveImageComponent,
    SafeResourcePipe,
    SafeHtmlPipe,
    TermsPageComponent,
    ListImagesComponent,
    YoutubePlayerComponent,
    YoutubePlayerModalComponent,
    LayoutHostListenerDirective,
    InputPhoneComponent,
    InputButtonGroupComponent,
    SelectLocationsComponent,
    FindLocationsComponent,
    PillsComponent,
    WorkingHoursComponent,
    FormStyledComponent,
    FormComponent,
    TwoBlocksComponent,
    HeroImageComponent,
    AlternatedRowsLayoutComponent,
    ImageTextColumnComponent,
    PromiseFeaturesComponent,
    EmergencyBannerComponent,
    AccordionImageLayoutComponent,
    DisplayOnDevice,
    DefineImageAlt,
    SimpleCheckboxComponent,
    ImageByDeviceComponent,
    CountdownModalComponent,
    RegExpDirective,
    AccessibilityComponent
  ],
  exports: [
    YouTubePlayerModule,
    CompareDirective,
    NumberOnlyDirective,
    LazyLoadImageDirective,
    SetLimitDirective,
    PhoneNumberValidatorDirective,
    WcagDirective,
    OnlyNumberDirective,
    WcagDirective,
    TagManagerLoadDirective,
    TagManagerClickDirective,
    TagManagerSubClickDirective,
    TagManagerFooterClickDirective,
    TagManagerHeaderClickDirective,
    ArraySortPipe,
    ModalComponent,
    DialogTriggerDismissalComponent,
    ErrorModalComponent,
    FormBaseComponent,
    TrialComponent,
    FormModalComponent,
    WarningModalComponent,
    EmergencyModalComponent,
    CorporateModalComponent,
    SweepstakesPopupComponent,
    LocationLeadPopupComponent,
    ContainerComponent,
    StickerComponent,
    ResponsiveImageComponent,
    TermsCheckboxComponent,
    TermsContentComponent,
    TermsPageComponent,
    ListImagesComponent,
    YoutubePlayerComponent,
    YoutubePlayerModalComponent,
    SafeResourcePipe,
    SafeHtmlPipe,
    LayoutHostListenerDirective,
    InputPhoneComponent,
    InputButtonGroupComponent,
    SelectModule,
    SelectLocationsComponent,
    FindLocationsComponent,
    PillsComponent,
    WorkingHoursComponent,
    FormStyledComponent,
    FormComponent,
    TwoBlocksComponent,
    HeroImageComponent,
    AlternatedRowsLayoutComponent,
    ImageTextColumnComponent,
    PromiseFeaturesComponent,
    EmergencyBannerComponent,
    AccordionImageLayoutComponent,
    DisplayOnDevice,
    DefineImageAlt,
    SimpleCheckboxComponent, 
    ImageByDeviceComponent,
    CountdownModalComponent,
    RegExpDirective,
    ScreenreaderErrorsComponent,
    AccessibilityComponent
  ],
})

export class SharedModule { }
