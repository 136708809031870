import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnDestroy, ViewChildren, inject, DestroyRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TermsData, TermsResponse } from './terms-content.types';
import { getOffsetTop } from '@core/helpers';
import { ITermsAndConditionsState } from '@store/reducers/terms-and-conditions.reducer.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WCAGUtils } from '@core/services/helpers/wcag.utils';

@Component({
  selector: 'bw-terms-content',
  templateUrl: './terms-content.component.html',
  styleUrls: ['./terms-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsContentComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  @ViewChild('container') container: ElementRef;
  @Output() onClose = new EventEmitter();
  @Input() hasCloseButton = true;
  @Input() anchorDifference = 10;
  @Input() active: number;
  @Input() data: TermsData[];
  @Input() containerSelector: string;
  loading = false;
  subs: Subscription[] = [];
  localData: (TermsResponse | ITermsAndConditionsState)[] = [];
  openedData: any;
  scrollElement: Window | Element;
  get activeText(): string {
    return this.data && this.data[this.active]?.text || '';
  }

  readonly TAB_INDEX_MILLI: number = 700;
  tabIndexTimeout: NodeJS.Timeout;

  onKeyPress = WCAGUtils.onKeyPress;

  /**
   * Lifecycle OnInit
   */
  ngOnInit(): void {
    this.scrollElement = this.getScrollElement();
  }

  /**
   * Lifecycle AfterViewInit
   */
  ngAfterViewInit(): void {
    this.setWcagAdjustments();
  }

  /**
   * Lifecycle OnChanges
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes?: SimpleChanges): void {
    if (changes?.data || this.active >= 0) {
      this.setLocalData();
    } else {
      this.openedData = undefined;
    }
  }

  /**
   * Get element to be scrolled
   */
  getScrollElement(): Window | Element {
    return (this.containerSelector) ? document.querySelector(this.containerSelector) : window;
  }

  /**
   * Sets the local data
   */
  setLocalData(): void {
    if (!this.data || (!this.active && this.active !== 0)) {
      return;
    }

    if (!this.subs[this.active]) {
      const item: TermsData | any = this.data[this.active] || {};

      return this.subs[this.active] = item.service.obs.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(this.onContentChange.bind(this));
    }

    this.openedData = this.localData[this.active];
  }

  /**
   *
   * @param {TermsResponse | ITermsAndConditionsState} data
   */
  onContentChange(data: TermsResponse | ITermsAndConditionsState): void {
    if (!data) {
      const activeItem = this.data[this.active];
      this.loading = true;
      this.openedData = {};

      if (activeItem.retrieve) {
        activeItem.retrieve();
      }

      return;
    }

    this.loading = false;
    this.setContent(data);
  }

  /**
   * Sets the content that will be consumed
   * @param {TermsResponse | ITermsAndConditionsState} data
   */
  setContent(data: any): void {
    const newData = data?.data ? data.data[this.data[this.active].id] : data;

    this.openedData = newData;
    this.localData[this.active] = newData;
    this.setWcagAdjustments();
  }

  /**
   * The container click event
   * @param event
   * @callback container click
   */
  onContainerClick(event: any): void {
    const path = event?.composedPath ? event.composedPath() : [];
    const href = path && (path[0]?.hash || path[1]?.hash);

    if (href && (href[0] === '#')) {
      event.preventDefault();
      
      this.scrollToElement(href);
    }
  }

  /**
   * Scrolls scrollElement to the desired position
   * @param {string} selector
   */
  scrollToElement(selector: string): void {
    const el = this.container.nativeElement.querySelector(selector);
    const firstHeading = el?.querySelector('h1,h2,h3,h4,h5,h6');
    firstHeading?.focus();
    const offsetPosition = getOffsetTop(el);
    const scrollElement = this.scrollElement || this.getScrollElement();

    scrollElement.scrollTo({
      top: Math.max(0, offsetPosition - this.anchorDifference),
      behavior: 'smooth',
    });
  }

  /**
   * Sets each section of the article with tabindex=-1 for it to be focusable on scroll, sets <hr> to aria-hidden=true
   * @param {string} selector
   */
  setWcagAdjustments(): void {
    if (this.tabIndexTimeout) {
      clearTimeout(this.tabIndexTimeout);
      this.tabIndexTimeout = null;
    }
    this.tabIndexTimeout = setTimeout(() => {
      const articleSections: HTMLElement[] = Array.from(document.body.querySelectorAll('section'));
      articleSections.forEach((section: HTMLElement) => {
        const firstHeading = section.querySelector('h1,h2,h3,h4,h5,h6');
        firstHeading?.setAttribute('tabindex', '-1');
      });
      const horizontalLines: HTMLElement[] = Array.from(document.getElementsByTagName('hr') || []);
      horizontalLines.forEach((hr: HTMLElement) => {
        hr.setAttribute('aria-hidden', 'true');
      });

      // if there's multiple spans inside <p>, set a default aria-label because VO will read them separately
      const customLabelElements: Element[] = Array.from(document.getElementsByClassName('custom-label'));
      customLabelElements.forEach((elements: HTMLElement) => {
        const spans: HTMLElement[] = Array.from(elements.querySelectorAll('span.include').length ? elements.querySelectorAll('span.include') : elements.querySelectorAll('span'));
        let paragraphLabel: string = ``;
        spans.forEach((span: HTMLElement) => {
          paragraphLabel += (span?.innerText || span?.textContent)?.trim(); + ' ';
          span.setAttribute('aria-hidden', 'true');
        })
        const paragraphStrippedText = paragraphLabel?.trim().replace(/(\r\n|\r|\n)/g, ' ').replace(/\s+/g, ' ');
        elements.setAttribute('aria-label', paragraphStrippedText);
      });
    }, this.TAB_INDEX_MILLI);
  }


  /**
   * Lifecycle OnDestroy
   */
  ngOnDestroy(): void {
    if (this.tabIndexTimeout) {
      clearTimeout(this.tabIndexTimeout);
      this.tabIndexTimeout = null;
    }
  }
}
