import { Injectable } from '@angular/core';
import { IPricingCookie } from '@public-pages/pages/checkout/checkout/checkout.types';

@Injectable({
    providedIn: 'root'
})
export class PlanPricingService {
    private _promoCodeName: string = '';
    private _pricingCookie: IPricingCookie | null = null;
    private _facilityId: string = '';

    get promoCodeName(): string  {
        return this._promoCodeName;
    }

    set promoCodeName(name: string) {
        this._promoCodeName = name;
    }
    
    get planPricingCookie(): IPricingCookie {
        return this._pricingCookie;
    }

    set planPricingCookie(cookie: IPricingCookie) {
        this._pricingCookie = cookie;
    }
    
    set facilityId(facilityId: string) {
        this._facilityId = facilityId;
    }

    get pricingStrategy(): boolean {
        return this.applyPricingStrategy();
    }

    /**
   * Validates if the pricing strategy is required
   * @returns {boolean}
   */
    private applyPricingStrategy(): boolean  {
        if (!this._pricingCookie) return false;
        return this._pricingCookie.idLocation ? this._pricingCookie.idLocation === this._facilityId : true;
    }

    get paymentOption(): string {
        return this._pricingCookie.paymentOption;
    }
}
