import { Injectable } from '@angular/core';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { environment } from '@environment';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
    CognitoIdentityCredentialProvider,
    CognitoIdentityCredentials,
    fromCognitoIdentityPool
} from '@aws-sdk/credential-provider-cognito-identity';
import { PublishCommand, SNSClient } from '@aws-sdk/client-sns';
import { IIdenityCredentialsPermissions } from './aws.service.types';

/**
 * Identity Credentials
 */
export type IdentityCredentials = {
    identityId: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
};

/**
 * Matches a JSON object.
 */
export type JsonObject = {[Key in string]: JsonValue} & {[Key in string]?: JsonValue | undefined};

/**
 * Matches a JSON array.
 */
export type JsonArray = JsonValue[] | readonly JsonValue[];

/**
 * Matches any valid JSON primitive value.
 */
export type JsonPrimitive = string | number | boolean | null;

/**
 * Matches any valid JSON value.
 */
export type JsonValue = JsonPrimitive | JsonObject | JsonArray;

@Injectable()
export class AwsService {
    private awsRegion: string;
    private idenityCredentialsProvider: CognitoIdentityCredentialProvider;
    private identityCredentials: CognitoIdentityCredentials;

    constructor(public logger: LoggerService) {
        // this.logger.info('-- AwsService init --');
        this.awsRegion = environment.AWS_CONF.region;
    }

    /**
     * Get Idenity Credentials
     * @returns {Promise<IdentityCredentials>} Identity Credentials
     * */
    async getIdenityCredentials(): Promise<IdentityCredentials> {
        this.identityCredentials = await this.idenityCredentialsProvider();
        return new Promise((resolve, reject) => {
            const permissions: IIdenityCredentialsPermissions = {
                identityId: this.identityCredentials.identityId,
                accessKeyId: this.identityCredentials.accessKeyId,
                secretAccessKey: this.identityCredentials.secretAccessKey,
                sessionToken: this.identityCredentials.sessionToken
            };
            permissions.identityId ? resolve(permissions) : reject();
        })
    }

    /**
     * Set Identity Credentials
     * @returns {Promise<boolean>} True if credentials are set
     * */
    setUnauthIdentityCredentials(): Promise<boolean> {
        return new Promise((resolve) => {
            const cognitoIdentityClient = new CognitoIdentityClient({ region: this.awsRegion });
            this.idenityCredentialsProvider = fromCognitoIdentityPool({
                client: cognitoIdentityClient,
                identityPoolId: environment.AWS_CONF.IdentityPoolId,
                logins: {}
            });
            resolve(true);
        });
    }

    /**
     * Publishes a message to an Amazon SNS topic.
     * @param message {JsonValue} The message to be published.
     * @param snsTopic {string} The SNS Topic ARN to which the message will be published.
     * @returns {Promise<PublishCommandOutput>} The response from the Publish operation.
     * */
    sendEmailWithSnS(message: JsonValue, snsTopic: string) {
        const sns = new SNSClient({
            region: this.awsRegion,
            credentials: this.identityCredentials
        });
        const publishCommand = new PublishCommand({
            Message: JSON.stringify({
                'default': JSON.stringify(message)
            }),
            MessageStructure: 'json',
            TopicArn: snsTopic
        });
        return sns.send(publishCommand);
    }

    sendContactUsEmail(message, snsTopic) {
        const sns = new SNSClient({
            region: this.awsRegion,
            credentials: this.identityCredentials
        });
        const publishCommand = new PublishCommand({
            Message: JSON.stringify({
                'default': JSON.stringify(message)
            }),
            MessageStructure: 'json',
            TopicArn: snsTopic
        });
        return sns.send(publishCommand);
    }
}
