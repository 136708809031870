import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { SpinnerService } from '@shared/components/spinner/spinner.module';
import { LoggerService } from '@core/services/log4ts/logger.service';
import { environment } from '@environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
    contador = 0;

    constructor(private spinnerService: SpinnerService, public logger: LoggerService, private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.contador++;
        if (this.contador === 1) {
            // this.spinnerService.show();
        }
        if (this.router.url !== '/'
            && this.router.url !== '/terms'
            && this.router.url !== '/privacy'
            && this.router.url !== '/personalTraining?icmp=hdr_module_PtBlink'
            && this.router.url !== '/whyblink?icmp=hdr_module_WhyBlink'
            && !req.url.includes('yext')
            && !req.url.includes('verify-email')
        ) {
            this.spinnerService.show();
        }

        let headers;
        if (
          req.url.includes('briteverify')
          || req.url.includes('api.yext.com')
        ) {
            headers = new HttpHeaders().set('Content-Type', 'application/json').delete('X-Amz-User-Agent');
        } else if (req.url.includes(environment.API_CONF.PRODUCT_CATALOG_API)) {
            headers = new HttpHeaders().set('x-api-key', environment.API_CONF.X_API_KEY_PRODUCT_CATALOG).delete('X-Amz-User-Agent');
        } else if (environment.envName !== 'prod' && req.url.includes('guerrillamail')) { // vlidate only on QA and Preprod environment
            headers = new HttpHeaders();
        } else {
            headers = new HttpHeaders().set('x-api-key', environment.API_CONF.X_API_KEY).delete('X-Amz-User-Agent');
        }

        const _req = req.clone({ headers: headers });
        return next.handle(_req).pipe(
            finalize(() => {
                this.contador--;
                if (this.contador === 0) {
                    this.spinnerService.hide();
                }
            })
        );
    }
}

