import { Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PopupService } from '@core/services/popup.service';
import { constants } from '@core/const/constants';
import { ToastService } from '@core/services/helpers/toast.service';
import { StreamService } from '@core/services/core/stream.service';
import { messagesOptions } from '@core/const/massage-options';
import { cleanSubscriptions, validateAllFormFields } from '@core/helpers';
import { LocalStorageService } from '@core/services/helpers/local-storage.service';
import { CmsService } from '@core/services/cms.service';
import { Subscription } from 'rxjs';
import { FacadeService } from '@core/services/core/facade.service';
import { SpinnerService } from '@shared/components/spinner/spinner.module';
import { GTMEvent, TagManagerClickDirectiveHelper } from '@shared/directives/tag-manager.directive';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const PATTERNS = constants.PATTERNS;
declare let $: any;

@Component({
    selector: 'bw-location-lead-popup',
    templateUrl: './locationLead-popup.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LocationLeadPopupComponent implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    private sub1: Subscription;
    private sub2: Subscription;
    subs: Subscription[] = [];
    locationLeadPopup: UntypedFormGroup;
    template: string;
    locations: any;
    formData: any;
    locationDataCMS: any = {};
    modalStatus = 'form';
    locationName: any; // get facility
    facility: any; // get facility
    clubCMSData: any; // get facility

    constructor(
        private fb: UntypedFormBuilder,
        private _facadeService: FacadeService,
        private _popupService: PopupService,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private _streamService: StreamService,
        private cmsService: CmsService,
        private gtmService: GoogleTagManagerService) {
    }

    ngOnInit() {
        this.template = '<img class="sp" src="./assets/img/loader.svg" alt="Loading..." />';
        this.setLocationLeadPopup();
        this.getLocationsPageData();

        $('#locationLeadPopup').on('hidden.bs.modal', (e) => {
            this._popupService.turnOffLocationLeadPopup();
            this.clearInputs();
        });
        this.createForm();
    }

    setLocationLeadPopup() {
        if (this.sub2 && !this.sub2.closed) { return; }
        this.sub2 = this._popupService._locationLeadPopup
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((res: boolean) => {
            if (res) {
                $('#locationLeadPopup').modal('show');
            } else {
                $('#locationLeadPopup').modal('hide');
            }
        });
    }

    getLocationsPageData() {
        if (this.sub1 && !this.sub1.closed) { return; }
        this.sub1 = this._streamService.locationsPageData.obs
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
                if (!data) {
                    this._streamService.getLocationsPageData();
                } else {
                    this.locationDataCMS = data;
                }
            });
    }



    createForm(): void {
        this.locationLeadPopup = this.fb.group({
            first_name: new UntypedFormControl('', [Validators.required]),
            last_name: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.pattern(PATTERNS.EMAIL)]),
            confirm_email: new UntypedFormControl('', [Validators.required, Validators.pattern(PATTERNS.EMAIL)]),
            zip: new UntypedFormControl('', [Validators.required, Validators.pattern(PATTERNS.ZIP)])
        });
    }

    clearInputs() {
        this.modalStatus = 'form';
        this.locationLeadPopup.reset();
    }

    submit(event: SubmitEvent): void {
        event.preventDefault();

        validateAllFormFields(this.locationLeadPopup);

        if (this.locationLeadPopup.valid) {
            this.spinnerService.show();
            this.formData = this.locationLeadPopup.getRawValue();
            this._streamService._nearestLocationsRequest.next(this.formData.zip);
            const _sub = this._streamService._nearestLocationsResponse.subscribe((near_locations: any[]) => {
                this.formData.facility = near_locations.shift();
                this._facadeService.processLocationLeadForm(this.formData)
                    .then(() => {
                        const pageData = TagManagerClickDirectiveHelper.getBodyPageData();
                        if(pageData != null){
                            this.gtmService.pushTag({
                                ...TagManagerClickDirectiveHelper.getDefaultEventData(GTMEvent.Click, pageData),
                                "clickType": "Location Lead - Completed",
                            });
                        }
                        this.spinnerService.hide();
                        this.locationLeadPopup.reset();
                        this.modalStatus = 'thanks';
                        _sub.unsubscribe();
                        LocalStorageService.setLocaStorage('leadFormSubmitted', true);
                        this.spinnerService.hide();
                    })
                    .catch((err) => {
                        this.spinnerService.hide();
                        _sub.unsubscribe();
                        // this._popupService.turnOffLocationLeadPopup();
                        if (err.status === 406) {
                            if (!err.error) {
                                err.error = {};
                            }
                            this.toastService.setToastVisible(messagesOptions.requestSweepstakesError(err.error.message));
                        } else {
                            this.toastService.setToastVisible(messagesOptions.requestError);
                        }
                        this.spinnerService.hide();
                    });
            });
        }

    }

    ngOnDestroy(): void {
        this.clearInputs();
        cleanSubscriptions(this.subs);
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        if (this.sub2) {
            this.sub2.unsubscribe();
        }
    }
}

